import React, { Component } from "react";

import Header from "../sections/commonSections/header/HeaderSection";
import Main from "../sections/commonSections/main/MainSection";
import Footer from "../sections/commonSections/footer/FooterSection";

import PersonalDataSection from "../sections/personalDataPage/personalDataBlock/PersonalDataSection";

import "../styles/index.scss";

class PersonalDataPolicy extends Component {
    render() {
        return (
            <div>
                <Header />
                <Main
                    title={"Политика в отношении обработки персональных данных"}
                />
                <PersonalDataSection />
                <Footer />
            </div>
        );
    }
}

export default PersonalDataPolicy;

export const Head = () => <title> HRFile </title>;
