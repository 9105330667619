import React, { Component } from "react";

import "./personalDataSectionStyles.scss";

class PersonalDataSection extends Component {
    render() {
        return (
            <section className="section personal-data-section">
                <div className="container">
                    <div className="personal-data-block">
                        <h2 className="personal-data-block-title">
                            Мы заботимся о защите персональных данных
                        </h2>

                        <p className="personal-data-block-paragraph mb-120">
                            Команда HRFile (ООО «Эйчар Файл») со всей
                            серьезностью относится к исполнению требований
                            Федерального закона от 27.07.2006 № 152-ФЗ «О
                            персональных данных» и к обеспечению безопасного
                            хранения персональной информации субъектов. В нашей
                            компании за организацию обработки персональных
                            данных отвечает отдельный сотрудник. Он следит за
                            тем, чтобы процессы по работе с информацией
                            соответствовали требованиям законодательства. Также
                            мы разработали политики и локальные акты, которые
                            регламентируют процедуры по работе с информацией,
                            обеспечивают безопасность данных, предотвращают и
                            выявляют нарушения законодательства. С этими
                            документами мы в обязательном порядке ознакомили под
                            роспись всех наших работников.
                        </p>

                        <h2 className="personal-data-block-title">
                            Контакты ответственного за обработку персональных
                            данных
                        </h2>
                        <p className="personal-data-block-paragraph mb-120">
                            <div>
                                <strong>Контактное лицо:</strong> Парфенова
                                Ольга Николаевна
                            </div>
                            <div>
                                <strong>Почта:</strong>{" "}
                                <a
                                    className="text-link"
                                    href="mailto:info@HRFile.ru"
                                >
                                    info@HRFile.ru
                                </a>
                            </div>
                        </p>

                        <h2 className="personal-data-block-title">
                            Политика обработки Персональных данных
                        </h2>
                        <p className="personal-data-block-paragraph">
                            Актуальная версия Политики в свободном доступе
                            расположена{" "}
                            <a className="text-link" href="/">
                                здесь
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PersonalDataSection;
